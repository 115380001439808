import { blue, yellow } from "@material-ui/core/colors";

export const baseTheme: any = {
  palette: {
    primary: {
      main: "#0075bf"
    },
    dark: {
      primary: {
        main: "#ddd"
      }
    },
    secondary: {
      main: "#000",
      dark: "#fff"
    },
    paper: {
      background: "#97C3F3"
    },
    background: {
      default: "#D9E1E2"
    }
  },

  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(",")
  },
  custom: {
    alert: {
      error: {
        color: "rgb(97,26,21)",
        backgroundColor: "rgb(253,236,234)",
        padding: "1em",
        borderRadius: "4px"
      },

      success: {
        color: "rgb(30, 70, 32)",
        backgroundColor: "rgb(237, 247, 237)",
        padding: "1em",
        borderRadius: "4px"
      }
    }
  },
  overrides: {
    MuiInput: {

    }
  }
};
