import React, { useContext } from "react";
import websitecontext from "../context/websitecontext";
import { Link, makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { ICaseData } from "../types";
import { useTranslation } from "react-i18next";
interface IFooter {
  isPreview: boolean;
}

const useStyles = makeStyles({
  footer: {},
  list: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1em",

    position: "relative",
    left: "0",
    bottom: "0",
    height: "50px",
    width: "100%",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginRight: "25px",
  },
  link: {
    textDecoration: "none",
  },
});

const Footer: React.FC<IFooter> = () => {
  const { t } = useTranslation();
  const context = useContext<ICaseData>(websitecontext);
  const classes = useStyles();
  const { IsPreview } = context.data;
  return (
    <footer color="primary" className={classes.footer}>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <Link to="/" component={NavLink} className={classes.link}>
            {t("Home")}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link to="/contact" component={NavLink} className={classes.link}>
            {t("Contact")}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link to="/privacy" component={NavLink} className={classes.link}>
            {t("Privacy Policy")}
          </Link>
        </li>
      </ul>
    </footer>
  );
};

export default React.memo(Footer);
