import { createMuiTheme } from "@material-ui/core";
import { baseTheme } from "./baseTheme";
import merge from "lodash/merge";
export const lightTheme: any = createMuiTheme(
  merge(baseTheme, {
    palette: {
      type: "light",
      background: {
        default: "#E8F2F2",
        paper: "#B9E2FD",
        
        
      },
      text: {
        primary: "#000"
      },
      textField: {
        label: "#0075bf"
      }
    }
  })
);
