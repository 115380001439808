import React from "react";
import { Box, Typography } from "@material-ui/core";

interface Props {}

const NotFound = (props: Props) => {
  return (
    <Box>
      <Typography>Website is unavailable</Typography>
    </Box>
  );
};

export default NotFound;
