import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  IconButton,
  useTheme,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Navigation from "./Navigation";
import DrawerNavigation from "./DrawerNavigation";
import { ILoginForm } from "../types";
import { useTranslation } from "react-i18next";

interface IHeader {
  isPreview: boolean;
  links: Array<ILoginForm>;
  toggleDarkMode: () => void;
  changeLanguage: (lang: string) => void;
  selectedLanguage: string;
  supportedLanguage: boolean;
}
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
    },

    backgroundColorLight: {
      backgroundColor: "#0075bf",
    },

    backgroundColorDark: {
      backgroundColor: "#293138",
    },

    menuButton: {
      marginRight: theme.spacing(2),
    },

    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    actionContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",

    },
    languagePicker: {
      color: "#fff",
      margin: "1em",
    },
  });
});

const Header: React.FC<IHeader> = ({
  isPreview,
  links,
  toggleDarkMode,
  changeLanguage,
  selectedLanguage,
  supportedLanguage,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  return (
    <AppBar
      className={
        theme.palette.type === "light"
          ? classes.backgroundColorLight
          : classes.backgroundColorDark
      }
    >
      <Toolbar variant="dense">
        {isMobile ?
          <>
            <IconButton
              onClick={handleDrawerToggle}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <DrawerNavigation
              caseForms={links}
              mobileOpen={mobileOpen}
              handleDrawerClose={handleDrawerClose}
              handleDrawerToggle={handleDrawerToggle}
            ></DrawerNavigation>
          </>
          : <Navigation caseForms={links}></Navigation>}

        <div className={classes.actionContainer}>
          <FormControl variant="outlined">
            <Select
              labelId="language-select"
              className={classes.languagePicker}

              value={
                selectedLanguage ? selectedLanguage : "us"
              }
              onChange={(e: any) => changeLanguage(e.target.value)}
            >
              <MenuItem value="us">English</MenuItem>
              <MenuItem value="es">Español</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            label={t(theme.palette.type)}
            control={<Switch color="default" onClick={toggleDarkMode}></Switch>}
          ></FormControlLabel>


        </div>





      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Header);
