import { createMuiTheme } from "@material-ui/core";
import { baseTheme } from "./baseTheme";
import merge from "lodash/merge";
export const darkTheme: any = createMuiTheme(
  merge(baseTheme, {
    palette: {
      type: "dark",
      background: {
        default: "#131E29",
        paper: "#303C48",
        
        
      },
      text: {
        primary: "#fff",
        hint: "#fff"
      },
    }
  })
);
