import React from "react";
import { NavLink } from "react-router-dom";
import { Link, makeStyles } from "@material-ui/core";
import { ILoginForm } from "../types";
import { useTranslation } from "react-i18next";
import websitecontext from "../context/websitecontext";
interface Props {
  caseForms: Array<ILoginForm>;
}
const useStyles = makeStyles({
  navItems: {
    display: "flex",
    listStyleType: "none",
    margin: "0",

    "& li": {
      margin: "1em",
      whiteSpace: "nowrap",
      fontSize: "1.3em",
    },
    "& a": {
      color: "white",
    },
  },
});
const Navigation: React.FC<Props> = ({ caseForms }) => {
  const context = React.useContext<any>(websitecontext);
  const { isPreview, previewKey, lang } = context;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <nav>
      <ul className={classes.navItems}>
        <li>
          <Link
            component={NavLink}
            to={isPreview ? `/?previewKey=${previewKey}&lang=${lang}` : "/"}
          >
            {t("Home")}
          </Link>
        </li>
        <li>
          <Link
            component={NavLink}
            to={
              isPreview ? `/contact?previewKey=${previewKey}&lang=${lang}` : "/contact"
            }
          >
            {t("Contact")}
          </Link>
        </li>

        {caseForms &&
          caseForms.map((caseForm: ILoginForm) => {
            let caseFormTo;

            caseFormTo = `/form/${caseForm.WebFormId}`



            return (
              <li key={caseForm.WebFormId}>
                <Link
                  component={NavLink}
                  to={
                    isPreview
                      ? `${caseFormTo}?previewKey=${previewKey}&lang=${lang}`
                      : `${caseFormTo}`
                  }
                >
                  {caseForm.WebsiteLinkTitle || caseForm.WebsiteFormTitle}
                </Link>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default Navigation;
