import React, { useState, useEffect } from "react";

import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import CaseWebsiteContext from "./context/websitecontext";
import Axios from "axios";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import i18n from "i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { darkTheme } from "./themes/dark";
import { lightTheme } from "./themes/light";
import { Helmet } from "react-helmet";
import NotFound from "./pages/NotFound";
import { setHours } from "date-fns/esm";
import AutoLogin from "./pages/AutoLogin";
const Home = React.lazy(() => import("./pages/Home"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const FormContainer = React.lazy(() => import("./pages/FormContainer"));
const useDarkMode = () => {
  const [theme, setTheme] = useState(lightTheme);

  const {
    palette: { type },
  } = theme;

  const toggleDarkMode = () => {
    const updatedTheme = type === "light" ? darkTheme : lightTheme;
    setTheme(updatedTheme);
  };

  return [theme, toggleDarkMode];
};
const App: React.FC = () => {
  const [theme, toggleDarkMode] = useDarkMode();
  const [domain, setDomain] = useState<string>("");
  const boxStyle = { marginTop: "100px" };
  const [caseInfo, setCaseInfo] = useState<any>(null);
  const [language, setLanguage] = useState<string>("");
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  const params = new URLSearchParams(document.location.search);

  const lang = params.get("lang");
  const previewKey = params.get("previewKey");

  if (lang && !language) {
    changeLanguage(lang);
  }

  useEffect(() => {
    const getCaseWebsiteData = () => {
      const hostName = previewKey || document.location.hostname;
      const redisKey =
        hostName.includes("localhost") || hostName === "192.168.35.104"
          ? `qaclient.simpluris.com`
          : hostName.replace("www.", "");

      Axios.get(
        `${process.env.REACT_APP_API_URL}/case?caseUrl=${redisKey}&lang=${language}`
      ).then((response: any) => {
        const { data } = response;
        const { Data } = data;
        if (previewKey) {
          setIsPreview(true);
          setDomain("qaclient.simpluris.com");
        } else {
          setIsPreview(false);
          setDomain(hostName);
        }

        setCaseInfo(Data);
      });
    };

    getCaseWebsiteData();
  }, [language, lang, previewKey]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Container maxWidth="xl">
          <CaseWebsiteContext.Provider
            value={{
              data: caseInfo,
              domain: domain,
              updateLanguage: setLanguage,
              isPreview: isPreview,
              previewKey: previewKey,
              lang: lang,
            }}
          >
            <BrowserRouter>
              {caseInfo ? (
                <>
                  {caseInfo.WebId !== 0 ? (
                    <>
                      <React.Suspense
                        fallback={
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              margin: "auto",
                            }}
                          >
                            <CircularProgress size={80} />
                          </div>
                        }
                      >
                        <React.Fragment>
                          <Helmet>
                            <title>{caseInfo.OfficialCaseName}</title>
                            <meta name="description" content="hello" />
                          </Helmet>
                          <Header
                            isPreview={false}
                            links={caseInfo.CaseForms}
                            toggleDarkMode={toggleDarkMode}
                            changeLanguage={changeLanguage}
                            selectedLanguage={language}
                            supportedLanguage={caseInfo.SupportedLanguage}
                          ></Header>
                        </React.Fragment>

                        <Box style={boxStyle}>
                          <Box component="main">
                            <Switch>
                              <Route exact path="/" component={Home} />
                              <Route
                                exact
                                path="/contact"
                                component={Contact}
                              />
                              <Route
                                exact
                                path="/privacy"
                                component={Privacy}
                              />
                              <Route
                                exact
                                path="/form/:webFormId"
                                component={FormContainer}
                              ></Route>
                              <Route
                                exact
                                path="/form/q/:webFormId/:webUserId"
                                component={FormContainer} />

                              <Route path="/" component={NotFound} />
                            </Switch>
                          </Box>
                        </Box>
                        <Footer isPreview={true} />
                      </React.Suspense>
                    </>
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : (
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: "auto",
                  }}
                >
                  <CircularProgress size={80} />
                </div>
              )}
            </BrowserRouter>
          </CaseWebsiteContext.Provider>
        </Container>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default App;
