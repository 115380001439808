import React from "react";
import {
  Drawer,
  useTheme,
  makeStyles,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  Link,
  Theme,
  createStyles,
  ListItemIcon,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { NavLink } from "react-router-dom";
import { ILoginForm } from "../types";
import { ContactMail, Home, Policy } from "@material-ui/icons";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { useTranslation } from "react-i18next";
interface Props {
  caseForms: Array<ILoginForm>;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  handleDrawerClose: () => void;
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
  })
);

const DrawerNavigation: React.FC<Props> = ({
  caseForms,
  mobileOpen,
  handleDrawerToggle,
  handleDrawerClose,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
                <ChevronRightIcon />
              )}
          </IconButton>
        </div>
        <Divider />

        <List>
          <ListItem>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText>
              <Link component={NavLink} to="/" onClick={handleDrawerClose}>
                {t("Home")}
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ContactSupportIcon />
            </ListItemIcon>
            <ListItemText>
              <Link
                component={NavLink}
                to="/contact"
                onClick={handleDrawerClose}
              >
                {t("Contact")}
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Policy />
            </ListItemIcon>
            <ListItemText>
              <Link
                component={NavLink}
                to="/privacy"
                onClick={handleDrawerClose}
              >
                {t("Privacy Policy")}
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          {caseForms &&
            caseForms.map((caseForm: ILoginForm) => {

              let caseFormTo;


              caseFormTo = `/form/${caseForm.WebFormId}`

              return (
                <ListItem key={caseForm.WebFormId}>
                  <ListItemIcon>
                    <ContactMail />
                  </ListItemIcon>
                  <ListItemText>
                    <Link
                      onClick={handleDrawerClose}
                      component={NavLink}
                      color="primary"
                      to={caseFormTo}
                    >
                      {caseForm.WebsiteLinkTitle || caseForm.WebsiteFormTitle}
                    </Link>
                  </ListItemText>
                </ListItem>
              );
            })}
        </List>
      </Drawer>
    </nav>
  );
};

export default DrawerNavigation;
