import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  us: {
    translation: {
      "Case Information": "Case Information",
      "Official Case Name": "Official Case Name",
      "Court Title": "Court Title",
      "Case No": "Case No",
      Phone: "Phone",
      "About this case": "About this case",
      "Case Forms": "Case Forms",
      "Case Documents": "Case Documents",
      "Important Dates": "Important Dates",
      "Privacy Policy": "Privacy Policy",
      Contact: "Contact",
      light: "light",
      dark: "dark",
      Home: "Home",
      "No Documents": "No Documents",
      "No Case Forms": "No Case Forms",
      test: `<0>This Privacy Policy has been developed by Simpluris, Inc.
      (“Simpluris") to protect its customers and class member’s (“users”)
      information from unauthorized release or use and for access to and/or
      processing of online response submissions in relation to this Website
      (“site”). For the purposes of this policy, any references to “us”,
      “our”, or “we” are meant to indicate Simpluris. This Privacy Policy
      describes how Simpluris may use and disclose any information that is
      collected about you through this site including any personally
      identifiable information. We gather information through the site
      either voluntarily, such as requesting individuals to fill out and
      submit online claim forms and/or involuntarily through technology,
      such as cookies and IP addresses, which can track your usage, activity
      and preferences. If you do not agree with the terms of this Privacy
      Policy, please do not use this Website.</0><1>Use of Information Gathered Through the Website</1><0>The information we collect may be used to process an online response
      submission, provide access to secure content, respond to an inquiry
      generated by the user, to diagnose problems with our systems,
      analyze trends, and/or track user navigation. Information provided
      on the Website is used to the extent necessary to complete the
      claims administration process.</0><1> Protection of Social Security Numbers and Other  Personally-Identifiable Information</1><0>As part of Simpluris' claims administration process, it may be necessary to collect non-public, personally-identifiable information
      such as your name, address, phone number, e-mail address, and social
      security number. Simpluris understands the importance, and is
      committed to, protecting the security of such information. Our
      personnel are required to follow specific security procedures for
      storing, using, and disposing of non-public personally-identifiable
      information provided to us. We may grant access to such information
      only to individuals who reasonably need it to accomplish the
      purposes for which the information was collected. Simpluris policy
      prohibits the unlawful disclosure of Social Security numbers or
      other non-public personally-identifiable information.</0><1>Disclosure of Information to Third-Parties</1><2>
      If necessary, we may disclose your information on an as-needed basis
      to companies and individuals we employ to perform business functions
      and services on our behalf to assist with the fulfillment of your
      request, to process your claim, or otherwise to fulfill our
      administrative duties and obligations. Before disclosing any
      information to an Outside Contractor we will require that it agrees
      to comply with our obligations under this Privacy Policy with
      respect to information it receives from us or from you as our
      Outside Contractor. We may also disclose information to the extent
      necessary or appropriate to government agencies, advisors, and other
      third parties in order to comply with applicable laws, the service
      of legal process, or if we reasonably believe that such action is
      necessary to (a)comply with the law requiring such disclosure; (b)
      protect the rights or property of Simpluris or its affiliated
      companies; (c) prevent a crime or protect national security or (d)
      protect the personal safety of the users or the public. We do not
      sell or rent any information you provide to us through the Website
      to third parties for marketing purposes.
      </2>
      <3>
      Data Security
      </3><4>
      Simpluris employs various technologies including SSL certificates,
      encryption, firewalls, and data loss prevention methods to protect
      the security of your information. And while we strive to protect
      your personal information, we cannot ensure or guarantee the
      security of the information you transmit to this Website. We
      recommend you take every precaution in protecting your personal
      information when you are on the Internet including changing your
      passwords often; using a combination of letters and numbers when
      creating passwords, and making sure you use a secure browser.
      </4>
      <5>Data Retention</5>
      <6>Simpluris will not retain your personal information longer than is
      necessary to fulfill the purposes for with it was collected or as
      required by applicable laws or regulations.</6>
      <7>Cookies</7>
      <8>We use cookies to store visitors preferences, record session
      information, record user-specific information on what pages users
      access, and record past activity at a site in order to provide
      better service when visitors return to our site.</8>
      <9>Links to Third-Party Websites</9>
      <10>The Website may contain links to information on other third-party
      websites. We encourage you to read the privacy statements of these
      third-party websites as they may differ from this Website. We are
      not responsible for the privacy practices of these other third-party
      websites and cannot guarantee the security of any personal
      information that may be collected there.</10>
      <11>Minors</11>
      <12>Simpluris does not knowingly collect information from children under
      the age of 13 through this Website. If you are under the age of 13,
      please do not use this Website or provide information to us. If we
      become aware that we have received private information from a child
      under the age of 13, we will make commercially reasonable efforts to
      delete that information from our database.</12>
      <13>Policy Revisions</13>
      <14>
      Simpluris reserves the right to update and repost this Privacy
            Statement without notification. However, Simpluris is under no duty
            or obligation to update the information on this Website. If you have
            any questions about our website privacy policies, please e-mail us
            at <15>privacy@simpluris.com</15>
      </14>

      .
      `,
    },
  },
  es: {
    translation: {
      "Case Information": "Información del caso",
      "Official Case Name": "Nombre oficial del caso",
      "Court Title": "Título de la corte",
      "Case No": "Número de caso",
      Phone: "Teléfono",
      "About this case": "Sobre este caso",
      "Case Forms": "Formularios de caso",
      "Case Documents": "Documentos de caso",
      "Important Dates": "Fechas importantes",
      "Privacy Policy": "Política de privacidad",
      Contact: "Contacto",
      light: "ligero",
      dark: "oscura",
      Home: "Casa",
      "No Documents": "Sin documentos",
      "No Case Forms": "No hay formularios de caso",
      test: `
      <0>Esta Política de privacidad ha sido desarrollada por Simpluris, Inc. ("Simpluris") para proteger a sus clientes y miembros de la clase ("usuarios") información de divulgación o uso no autorizado y para acceso y / o procesamiento de envíos de respuestas en línea en relación con este sitio web
("sitio"). A los fines de esta política, cualquier referencia a "nosotros",
"Nuestro" o "nosotros" están destinados a indicar Simpluris. Esta Política de privacidad describe cómo Simpluris puede usar y divulgar cualquier información que sea recopilado sobre usted a través de este sitio, incluyendo cualquier persona Información identificable. Recopilamos información a través del sitio. ya sea voluntariamente, como solicitar a las personas que completen y enviar formularios de reclamo en línea y / o involuntariamente a través de la tecnología, como cookies y direcciones IP, que pueden rastrear su uso, actividad y preferencias Si no está de acuerdo con los términos de esta Privacidad Política, no utilice este sitio web.</0>

<1>Uso de la información recopilada a través del sitio web </1>
<0>La información que recopilamos puede usarse para procesar una respuesta en línea envío, proporcionar acceso a contenido seguro, responder a una consulta generado por el usuario, para diagnosticar problemas con nuestros sistemas, analizar tendencias y / o rastrear la navegación del usuario. Información provista en el sitio web se utiliza en la medida necesaria para completar el proceso de administración de reclamos. </0> <1> Protección de números de seguro social y otra información de identificación personal </1> <0> Como parte del proceso de administración de reclamos de Simpluris, puede ser necesario recolectar personalmente a personas no públicas. información identificable como su nombre, dirección, número de teléfono, dirección de correo electrónico y redes sociales numero de seguridad. Simpluris entiende la importancia y es comprometido a proteger la seguridad de dicha información. Nuestra
el personal debe seguir procedimientos de seguridad específicos para almacenamiento, uso y eliminación de identificación personal no pública información provista a nosotros. Podemos otorgar acceso a dicha información solo a individuos que lo necesiten razonablemente para lograr propósitos para los cuales se recopiló la información. Política de Simpluris prohíbe la divulgación ilegal de números de Seguro Social o  otra información de identificación personal no pública.</0>
<1>Divulgación de información a terceros</1>
<2>Si es necesario, podemos divulgar su información según sea necesario a empresas e individuos que empleamos para realizar funciones comerciales y servicios en nuestro nombre para ayudar con el cumplimiento de su solicitud, para procesar su reclamo o para cumplir con nuestro deberes y obligaciones administrativas. Antes de revelar cualquier información a un contratista externo, le solicitaremos que acepte para cumplir con nuestras obligaciones bajo esta Política de privacidad con
respecto a la información que recibe de nosotros o de usted como nuestro
Contratista externo También podemos divulgar información en la medida
necesario o apropiado para agencias gubernamentales, asesores y otros
terceros para cumplir con las leyes aplicables, el servicio
del proceso legal, o si creemos razonablemente que tal acción es
necesario para (a) cumplir con la ley que exige dicha divulgación; (si)
proteger los derechos o la propiedad de Simpluris o sus afiliados
compañías; (c) prevenir un delito o proteger la seguridad nacional o (d)
Proteger la seguridad personal de los usuarios o del público. Nosotros no
vender o alquilar cualquier información que nos proporcione a través del sitio web
a terceros con fines de marketing.
</2>
<3>Seguridad de datos</3>

<4>Simpluris emplea diversas tecnologías, incluidos certificados SSL,
cifrado, cortafuegos y métodos de prevención de pérdida de datos para proteger
La seguridad de su información. Y mientras nos esforzamos por proteger
su información personal, no podemos garantizar ni garantizar la
seguridad de la información que transmite a este sitio web. Nosotros
Le recomendamos que tome todas las precauciones para proteger su personal
información cuando está en Internet, incluido el cambio de
contraseñas a menudo; usando una combinación de letras y números cuando
creando contraseñas y asegurándose de usar un navegador seguro.
</4>
<5>Retención de datos </5>
<6>Simpluris no retendrá su información personal por más tiempo
 necesario para cumplir con los fines para los cuales fue recolectado o como
requerido por las leyes o regulaciones aplicables. </6>
<7>Cookies</7>

<8>Utilizamos cookies para almacenar las preferencias de los visitantes, grabar sesión
información, registre información específica del usuario en qué páginas los usuarios
acceder y registrar la actividad pasada en un sitio para proporcionar
mejor servicio cuando los visitantes regresen a nuestro sitio. </8>
<9>Enlaces a sitios web de terceros </9>
<10>El sitio web puede contener enlaces a información sobre terceros
sitios web Le recomendamos que lea las declaraciones de privacidad de estos
sitios web de terceros, ya que pueden diferir de este sitio web. Estamos
no nos hacemos responsables de las prácticas de privacidad de estos terceros
sitios web y no puede garantizar la seguridad de ningún personal
información que se puede recopilar allí.</10>
<11>Menores </11>
<12>Simpluris no recopila a sabiendas información de niños menores de
la edad de 13 años a través de este sitio web. Si tienes menos de 13 años,
no utilice este sitio web ni nos brinde información. Si nosotros
darse cuenta de que hemos recibido información privada de un niño
menores de 13 años, haremos esfuerzos comercialmente razonables para
eliminar esa información de nuestra base de datos.</12>
<13>Revisiones de políticas </13>
<14>Simpluris se reserva el derecho de actualizar y volver a publicar esta Privacidad
Declaración sin notificación. Sin embargo, Simpluris no tiene ningún deber
u obligación de actualizar la información en este sitio web. Si usted tiene
cualquier pregunta sobre las políticas de privacidad de nuestro sitio web, envíenos un correo electrónico en <15> privacy@simpluris.com </15></14> .
      `,
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "us",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
